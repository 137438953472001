import LazyloadImage from '../LazyloadImage';

const PageContentRowLargeImage = (props) => {

  const { row, defaultColumnClasses } = props;
  const { value } = row;

  if (value && value?.image?.path) {
    return (
      <div className="row page__row">
        <div className={defaultColumnClasses}>
          <div className="image__wrapper image__wrapper--full-width page__image__wrapper">
            <LazyloadImage image={value.image} />
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default PageContentRowLargeImage;