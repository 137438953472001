import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const IframeOverlay = (props) => {

  const { windowWidth, windowHeight, value, setActiveIframeOverlay } = props;
  const { url, showDevices, defaultDevice, showWebsiteLink } = value;
  const [device, setDevice] = useState('desktop');
  const [iframeIsLoaded, setIframeIsLoaded] = useState(false);

  useEffect(() => {
    if (defaultDevice) {
      setDevice(defaultDevice);
    }
  }, [defaultDevice]);

  useEffect(() => {

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setActiveIframeOverlay({});
      }
    }

    window.addEventListener('keydown', handleKeyDown, false);

    return () => {
      window.removeEventListener('keydown', handleKeyDown, false);
    }
  })

  if (url) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: iframeIsLoaded === true ? 1 : 0 }}
        exit={{ opacity: 0 }}
        className="iframe-overlay"
        style={{
          width: windowWidth + 'px',
          height: windowHeight + 'px'
        }}
      >
        <button
          className="iframe-overlay__inner__close-button--massive"
          onClick={() => setActiveIframeOverlay({})}
          aria-label="Close overlay"
        />
        <div className="iframe-overlay__inner">
        {
          windowWidth >= 768 && showDevices === true &&
          <div className="page__iframe__controls">
            <button
              className={`page__iframe__button${device === 'mobile' ? ' active' : ''}`}
              onClick={() => setDevice('mobile')}
            >mobile</button>
            <button
              className={`page__iframe__button${device === 'tablet' ? ' active' : ''}`}
              onClick={() => setDevice('tablet')}
            >tablet</button>
            <button
              className={`page__iframe__button${device === 'desktop' ? ' active' : ''}`}
              onClick={() => setDevice('desktop')}
            >desktop</button>
          </div>
        }
        <div
          className={`page__iframe__wrapper page__iframe__wrapper--${device}`}
        >
          <motion.iframe
            animate={{ opacity: iframeIsLoaded === true ? 1 : 0 }}
            onLoad={() => {
              setIframeIsLoaded(true);
            }}
            allow="camera *;microphone *"
            title="website example"
            frameBorder="0"
            src={url}
            className={`page__iframe${iframeIsLoaded === true ? ' loaded' : ''}`}
          />
          </div>
          {
            showWebsiteLink === true &&
            <a
              href={url} className="page__iframe__link" rel="noreferrer" target="_blank"
            >Visit website</a>
          }
        </div>
          <button
            className="iframe-overlay__inner__close-button--small"
            onClick={() => setActiveIframeOverlay({})}
            aria-label="Close overlay"
          >Close</button>
      </motion.div>
    );
  } else {
    return null;
  }
}

export default IframeOverlay;