import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Footer from '../components/Footer';
import LazyloadImage from './LazyloadImage';

const ClientHub = (props) => {

  const { pages, windowWidth, windowHeight } = props;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="page page--client-hub"
      style={{
        width: windowWidth + 'px',
        height: windowHeight + 'px'
      }}
    >
      <div className="container-fluid"
        style={{
          minHeight: windowHeight - 96 + 'px',
        }}
      >
        <div className="row">
          {
            pages.map(
              (page, index) => (
                <div
                  key={index} className="page-thumbnail__wrapper col-xxs-12 col-sm-6 col-md-4 col-lg-3"
                >
                  <Link
                    to={`/page/${page._id}`}
                    className="page-thumbnail__link"
                  >
                    {
                      page.thumbnail?.path &&
                      <LazyloadImage image={page.thumbnail} isBackgroundImage={true} />
                    }
                    <h3 className="page-thumbnail__name">{page.name}</h3>
                  </Link>
                </div>
              )
            )
          }
        </div>
      </div>
      <Footer {...props} />
    </motion.div>
  );
};

export default ClientHub;