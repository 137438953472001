import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Content from './components/Content';
import { fetchCockpitDataFromMain } from './utils/cockpit';

const App = (props) => {

  const [windowDimensions, setWindowDimensions] = useState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth
  });
  
  const [client, setClient] = useState({});
  const [isSignedIn, setIsSignedIn] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [pages, setPages] = useState([]);
  const [contact, setContact] = useState({});
  const [previewItem, setPreviewItem] = useState({});
  const [currentPage, setCurrentPage] = useState({});

  useEffect(() => {
    if (location.pathname.indexOf('/page/') !== 0) {
      setCurrentPage({});
    }
  }, [location.pathname]);

  useEffect(() => {
    fetchCockpitDataFromMain('contact', setContact, false);
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
      });
    }
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <Content
      {...props}
      navigate={navigate}
      location={location}
      pathname={location.pathname}
      {...windowDimensions}
      pages={pages}
      setPages={setPages}
      previewItem={previewItem}
      setPreviewItem={setPreviewItem}
      client={client}
      setClient={setClient}
      isSignedIn={isSignedIn}
      setIsSignedIn={setIsSignedIn}
      contact={contact}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
    />
  );
}

export default App;
