import { Fragment } from 'react';
import PageContentRow from './PageContentRow';

const PageContent = (props) => {

  const { page } = props;
  const { contentBlocks } = page;

  if (contentBlocks && contentBlocks[0]) {
    return (
      <Fragment>
        {
          contentBlocks.map(
            (row, i) => (
              <PageContentRow {...props} row={row} key={i} />
            )
          )
        }
      </Fragment>
    );
  } else {
    return null;
  }
}

export default PageContent;