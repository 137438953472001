const LogoTop = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1366" height="182.978" viewBox="0 0 1366 182.978" className="logo logo--top">
    <g transform="translate(0 -429)">
      <path d="M122.351,453.652c-16.121-14.943-38.736-22.517-67.216-22.517H6.658A6.659,6.659,0,0,0,0,437.793V600.928a6.659,6.659,0,0,0,6.658,6.658h52.67c27.536,0,49.253-7.969,64.552-23.688,15.248-15.672,22.979-37.228,22.979-64.07C146.859,491,138.615,468.731,122.351,453.652Zm-4.889,65.708c0,20.747-5.109,36.375-15.181,46.448s-25,15.177-44.35,15.177H28.7V457.736H56.068c20.4,0,35.885,5.283,46.032,15.7C112.294,483.906,117.462,499.356,117.462,519.361Z" transform="translate(0 1.127)"/>
      <path d="M183.768,515.853l51.648-74.259a6.657,6.657,0,0,0-5.466-10.459H212.471a6.658,6.658,0,0,0-5.492,2.893l-36.218,52.816q-1.414,2.186-4.249,6.426L126.4,434.061a6.658,6.658,0,0,0-5.514-2.926H102.7a6.659,6.659,0,0,0-5.472,10.453l51.659,74.493L92.123,597.107a6.659,6.659,0,0,0,5.454,10.479h17.71a6.655,6.655,0,0,0,5.5-2.9l41.463-60.561q2.131-3.078,3.784-5.471L211.414,604.7a6.662,6.662,0,0,0,5.485,2.883h18.409a6.658,6.658,0,0,0,5.451-10.482Z" transform="translate(47.982 1.127)"/>
      <path d="M304.913,519.631a54.058,54.058,0,0,0,10.612-9.247c7.223-8.134,10.887-18.211,10.887-29.941,0-15.372-5.4-27.643-16.046-36.473-10.276-8.517-24.132-12.835-41.186-12.835H202.525a6.659,6.659,0,0,0-6.659,6.658V600.928a6.659,6.659,0,0,0,6.659,6.658H217.91a6.659,6.659,0,0,0,6.659-6.658V533.712H264.75c10.624,0,18.333,1.917,22.909,5.7,4.2,3.473,6.325,10.606,6.325,21.205v40.316a6.658,6.658,0,0,0,6.658,6.658h15.612a6.659,6.659,0,0,0,6.659-6.658V558.051c0-12.712-3.091-22.951-9.185-30.422A38.9,38.9,0,0,0,304.913,519.631Zm-80.344-61.9h41.814c10.35,0,18.321,2.176,23.691,6.47,4.982,3.983,7.4,9.75,7.4,17.635,0,8.054-2.582,14.061-7.9,18.36-5.669,4.585-13.554,6.909-23.43,6.909H224.569Z" transform="translate(103.367 1.127)"/>
      <path d="M345.749,514.776a6.905,6.905,0,0,1-2.064,5.042,6.778,6.778,0,0,1-4.994,2.082,6.92,6.92,0,0,1-5.127-2.082,7.056,7.056,0,0,1-2.064-5.174,7.085,7.085,0,0,1,7.191-7.125,6.775,6.775,0,0,1,4.994,2.085A7.065,7.065,0,0,1,345.749,514.776Z" transform="translate(174.947 41.437)"/>
      <path d="M412.637,517.566a17.254,17.254,0,0,1-.533,2.93,135.72,135.72,0,0,0-3.063,16.246H369.088q-3.593,0-3.6-1.06a2.965,2.965,0,0,1,.4-.8q2.931-5.17,8.789-15.375l23.174-38.832a66.326,66.326,0,0,0-8.392-.4q-8.525,0-11.987,3.129t-5.459,12.451q-.4,1.6-1.734,1.6a.941.941,0,0,1-1.063-1.066q0-.131.933-5.726a102.558,102.558,0,0,1,2.663-12.786q9.32.4,35.292.4h1.729a1.519,1.519,0,0,1,.134.663q0,1.46-7.034,13.256-15.684,26.654-25.249,42.248,3.6.032,8.668.031,15.874,0,19.476-5.815a35.44,35.44,0,0,0,4.266-11.107,1.567,1.567,0,0,1,1.6-1.19Q412.635,516.367,412.637,517.566Z" transform="translate(192.886 25.796)"/>
      <path d="M456.6,508.165q0,12.92-7.859,21.64a25.26,25.26,0,0,1-19.576,8.723,26.5,26.5,0,0,1-20.238-8.723q-8.126-8.72-8.126-21.64,0-13.312,7.924-22.1a25.518,25.518,0,0,1,19.774-8.789,25.994,25.994,0,0,1,20.044,8.856Q456.6,494.982,456.6,508.165Zm-12.118-.133q0-14.648-4.528-22.771-3.332-5.995-11.188-5.993-7.457,0-11.654,7.657t-4.194,21.24q0,13.454,4.461,20.976t12.453,7.524q6.125,0,9.058-4.661Q444.473,523.213,444.477,508.032Z" transform="translate(211.518 25.475)"/>
      <path d="M503.924,536.2q0,.995-2,.995h-24.5c-1.153,0-1.729-.312-1.729-.93a.762.762,0,0,1,.732-.8q.726-.131,5.127-.266c1.684-.087,2.663-.332,2.929-.732s.4-1.836.4-4.317V504.648q0-14.481-1.066-17.271-2.262-5.976-9.055-5.98-6.662,0-11.188,5.847a7.664,7.664,0,0,0-1.332,4.783v41.449q0,1.6,2.845,1.728l4.061.133c.9,0,1.352.31,1.352.93s-.533.93-1.6.93H444.663c-1.334,0-2-.272-2-.816s.8-.894,2.4-1.05h3.329c1.329,0,2.13-.31,2.4-.929l.4-1.992V503.458q0-15.143-.266-16.736t-3.33-1.595H443.73c-1.778,0-2.663-.328-2.663-.99q0-1.272,2.8-1.274a35.8,35.8,0,0,0,8.655-1.465q4.661-1.329,6.528-3.062a3.4,3.4,0,0,1,1.862-1.065c.622,0,.933.845.933,2.528v6.392q6.925-8.919,17.843-8.92,9.588,0,13.851,7.058,2.4,3.994,2.4,15.18v30.364q0,4.265.4,4.661.8.8,3.862.8h2.4C503.478,535.333,503.924,535.625,503.924,536.2Z" transform="translate(232.77 25.475)"/>
      <path d="M534.934,522.416q0,1.331-2.064,4.727a29.493,29.493,0,0,1-4.194,5.527q-5.727,5.86-15.314,5.859a26.25,26.25,0,0,1-20.044-8.656q-8.062-8.653-8.056-21.44,0-13.314,7.656-22.241a24.168,24.168,0,0,1,19.112-8.92,23.376,23.376,0,0,1,15.846,5.527,19.152,19.152,0,0,1,6.792,14.182,68.129,68.129,0,0,1-11.318.533h-9.325q-.93,0-15.98.4a55.619,55.619,0,0,0-.666,8.361q0,13.4,5.127,20.634t14.449,7.231a13.857,13.857,0,0,0,8.522-2.4q3.2-2.395,6.925-9.188a1.629,1.629,0,0,1,1.465-1.066C534.58,521.482,534.934,521.8,534.934,522.416Zm-11.583-27.168q-.536-15.982-11.455-15.98-10.787,0-13.45,15.98Z" transform="translate(256.094 25.475)"/>
      <g transform="translate(866.367 429)">
        <path d="M723.052,455.41C705.438,437.885,683.668,429,658.343,429s-47.1,8.885-64.7,26.41c-17.616,17.531-26.549,39.347-26.549,64.847s8.925,47.38,26.525,65.054c17.6,17.7,39.381,26.667,64.729,26.667s47.119-8.971,64.73-26.664,26.526-39.57,26.526-65.057S740.668,472.944,723.052,455.41ZM658.343,587.242c-18.66,0-33.913-6.32-46.625-19.321h0c-12.76-13.045-18.961-28.635-18.961-47.664,0-19.014,6.193-34.522,18.935-47.407,12.717-12.859,27.978-19.111,46.653-19.111s33.985,6.256,46.783,19.124c12.812,12.885,19.039,28.387,19.039,47.394,0,19.023-6.235,34.609-19.066,47.65C692.306,580.919,677.012,587.242,658.343,587.242Z" transform="translate(-567.089 -429)"/>
        <path d="M682.916,514.764H670.8a6.66,6.66,0,0,0-6.617,5.9c-.576,5.049-2.507,8.807-5.9,11.493-3.642,2.877-8.085,4.275-13.586,4.275-7.313,0-12.883-2.418-17.028-7.4-4.449-5.332-6.612-12.4-6.612-21.6,0-9.047,2.281-16.194,6.974-21.848a20.525,20.525,0,0,1,16.666-7.848,19.865,19.865,0,0,1,12.955,4.239,15.34,15.34,0,0,1,5.839,10.456,6.66,6.66,0,0,0,6.6,5.813h12.121a6.66,6.66,0,0,0,6.626-7.319c-1.109-11.108-5.888-20.157-14.208-26.89-8.1-6.552-18.168-9.872-29.938-9.872-14.141,0-26.094,5.148-35.523,15.294-9.3,10.011-14.02,22.789-14.02,37.975,0,15.847,4.58,28.683,13.608,38.151,9.11,9.568,21.121,14.419,35.7,14.419,12.239,0,22.67-3.442,31.009-10.238,8.606-7.02,13.346-16.412,14.089-27.919a6.658,6.658,0,0,0-6.646-7.087Z" transform="translate(-552.277 -415.717)"/>
      </g>
      <path d="M834.359,581.651H756.509c3.835-3.977,10.155-9.6,20.568-17.392l14.232-10.735c15.956-12.124,27.813-23.356,35.236-33.387a58.981,58.981,0,0,0,11.907-35.771c0-16.6-5.494-30.162-16.33-40.293C811.414,434.072,797.5,429,780.755,429c-17.314,0-31.847,5.842-43.2,17.369-11.325,11.493-17.066,26.552-17.066,44.757v4.424a6.659,6.659,0,0,0,6.659,6.658H742.3a6.658,6.658,0,0,0,6.658-6.658v-3.026c0-12.1,2.922-21.3,8.934-28.1,5.8-6.565,13.276-9.753,22.86-9.753,9.043,0,15.959,2.672,21.142,8.16,5.286,5.616,7.856,12.735,7.856,21.772a35.484,35.484,0,0,1-7.388,22.2c-5.427,7.164-13.091,14.44-22.852,21.68L756.01,546.4c-13.281,10-22.458,18.813-28.051,26.946a52.617,52.617,0,0,0-9.325,28.485,6.655,6.655,0,0,0,6.655,6.882h109.07a6.659,6.659,0,0,0,6.659-6.658V588.308A6.659,6.659,0,0,0,834.359,581.651Z" transform="translate(379.253 0)"/>
      <rect width="33.592" height="36.388" rx="4.358" transform="translate(1332.408 572.326)"/>
      <path d="M869.05,430.63H855.381a5.358,5.358,0,0,0-5.028,3.561A48.455,48.455,0,0,1,835,455.416a54.479,54.479,0,0,1-24.52,11.542A5.367,5.367,0,0,0,806.1,472.2V488.13a5.36,5.36,0,0,0,6.1,5.3,49.784,49.784,0,0,0,19.161-6.916A52.67,52.67,0,0,0,847.4,471.54c-.093,3.2-.141,7.408-.141,12.57v118.4a5.353,5.353,0,0,0,5.347,5.347H869.05a5.354,5.354,0,0,0,5.347-5.347V435.977A5.355,5.355,0,0,0,869.05,430.63Z" transform="translate(425.415 0.86)"/>
    </g>
  </svg>
);

const LogoBottom = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1366" height="228.553" viewBox="0 0 1366 228.553" className="logo logo--bottom">
    <g transform="translate(0 -572.01)">
      <path d="M122.05,594.471C105.969,579.566,83.41,572.01,55,572.01H6.641A6.642,6.642,0,0,0,0,578.651v162.73a6.642,6.642,0,0,0,6.641,6.641H59.181c27.468,0,49.13-7.949,64.391-23.629,15.21-15.633,22.922-37.135,22.922-63.911C146.494,631.723,138.27,609.513,122.05,594.471Zm-4.88,65.545c0,20.7-5.1,36.285-15.143,46.332s-24.933,15.139-44.24,15.139H28.63V598.545h27.3c20.349,0,35.8,5.27,45.918,15.662C112.014,624.65,117.17,640.062,117.17,660.016Z" transform="translate(0 0)"/>
      <path d="M4.359,0h18.98A4.358,4.358,0,0,1,27.7,4.358V120.744a4.358,4.358,0,0,1-4.358,4.358H4.358A4.358,4.358,0,0,1,0,120.744V4.359A4.359,4.359,0,0,1,4.359,0Z" transform="translate(162.263 622.921)"/>
      <rect width="29.792" height="36.531" rx="4.358" transform="translate(161.102 578.055)"/>
      <path d="M245.34,606.536H230.928a6.644,6.644,0,0,0-6.643,6.643v.92c-8.592-7.193-19.51-10.818-32.646-10.818a53.906,53.906,0,0,0-41.154,18.071c-10.881,11.83-16.4,27.308-16.4,45.994,0,18.632,5.287,34.1,15.71,45.988,10.648,12.146,24.49,18.3,41.143,18.3,10.665,0,20.211-2.565,28.373-7.621a51.086,51.086,0,0,0,4.973-3.5v6.57c0,10.823-2.632,18.941-7.819,24.13-5.262,5.264-12.7,7.819-22.737,7.819-8.164,0-14.442-2.041-19.2-6.24-4.615-4.077-7.039-9.482-7.412-16.527a6.64,6.64,0,0,0-6.632-6.291H146.073a6.644,6.644,0,0,0-6.641,6.807c.361,14.308,5.678,25.962,15.8,34.633,9.877,8.465,22.906,12.758,38.726,12.758,18.388,0,32.829-5.344,42.923-15.881,10.018-10.456,15.1-25.651,15.1-45.162V613.179A6.644,6.644,0,0,0,245.34,606.536Zm-82.86,60.81c0-12.137,2.949-21.535,9.014-28.734,5.777-6.856,13.05-10.191,22.234-10.191,9.3,0,16.591,3.386,22.294,10.349,6.027,7.365,8.958,16.637,8.958,28.342,0,12.175-2.931,21.671-8.958,29.036-5.7,6.963-13.068,10.349-22.524,10.349a27.021,27.021,0,0,1-21.968-10.378C165.441,688.76,162.48,679.349,162.48,667.346Z" transform="translate(70.253 16.384)"/>
      <rect width="29.792" height="36.531" rx="4.358" transform="translate(342.888 578.055)"/>
      <path d="M4.359,0h18.98A4.358,4.358,0,0,1,27.7,4.358V120.744a4.358,4.358,0,0,1-4.358,4.358H4.358A4.358,4.358,0,0,1,0,120.744V4.359A4.359,4.359,0,0,1,4.359,0Z" transform="translate(344.049 622.921)"/>
      <path d="M322.328,718.806a6.647,6.647,0,0,0-5.648-1.254,33.207,33.207,0,0,1-7.539.756c-3.859,0-6.736-.727-8.33-2.109-1.273-1.1-2-4.011-2-7.99V643.251h18a6.644,6.644,0,0,0,6.643-6.643V624.288a6.644,6.644,0,0,0-6.643-6.643h-18V588.719a6.642,6.642,0,0,0-6.643-6.641H277.754a6.642,6.642,0,0,0-6.643,6.641v28.926H255.437a6.642,6.642,0,0,0-6.641,6.643v12.319a6.642,6.642,0,0,0,6.641,6.643h15.674v65.888c0,12.025,3.38,21.094,10.041,26.954,6.414,5.651,14.735,8.517,24.732,8.517a63.95,63.95,0,0,0,13.758-1.55,6.647,6.647,0,0,0,5.206-6.487V724.015A6.645,6.645,0,0,0,322.328,718.806Z" transform="translate(130.355 5.275)"/>
      <path d="M354.417,603.281c-14.7,0-26.713,4.284-35.7,12.732a46.3,46.3,0,0,0-14.863,31.322,6.64,6.64,0,0,0,6.623,7.123h14.647a6.644,6.644,0,0,0,6.631-6.247c.366-6.1,2.344-10.846,6.045-14.488,3.581-3.519,9.325-5.3,17.08-5.3,6.631,0,11.678,1.567,15.431,4.79,3.5,3,5.123,6.908,5.123,12.281,0,1.077,0,4.357-9.971,5.991l-23.5,3.723c-34.4,5.55-41.617,24.833-41.617,40.034,0,11.9,4.342,21.655,12.9,28.988,8.255,7.076,18.741,10.666,31.169,10.666,10.2,0,19.244-2.409,26.879-7.167q2.178-1.358,4.139-2.874V725a6.642,6.642,0,0,0,6.643,6.641h14.182A6.641,6.641,0,0,0,402.906,725V650.141c0-14.023-4.378-25.465-13.013-34.011C381.275,607.6,369.34,603.281,354.417,603.281Zm21.023,70.973v6.574a28.455,28.455,0,0,1-8.246,20.724c-5.488,5.675-12.4,8.435-21.143,8.435-5.509,0-9.778-1.4-13.049-4.275a12.982,12.982,0,0,1-4.484-10.472c0-3.43,0-12.548,19.787-16l19.674-3.283C370.8,675.424,373.272,674.864,375.44,674.254Z" transform="translate(157.368 16.384)"/>
      <rect width="27.697" height="176.014" rx="4.358" transform="translate(580.464 572.01)"/>
      <path d="M565.232,721.487H481.674V671.772h77.048a6.642,6.642,0,0,0,6.643-6.641V651.878a6.642,6.642,0,0,0-6.643-6.641H481.674V598.545h80.767a6.644,6.644,0,0,0,6.643-6.643V578.651a6.642,6.642,0,0,0-6.643-6.641H459.688a6.641,6.641,0,0,0-6.641,6.641v162.73a6.641,6.641,0,0,0,6.641,6.641H565.232a6.642,6.642,0,0,0,6.641-6.641V728.129A6.642,6.642,0,0,0,565.232,721.487Z" transform="translate(237.371 0)"/>
      <path d="M599.553,603.281a45.95,45.95,0,0,0-26.407,7.9,60.428,60.428,0,0,0-4.843,3.676v-1.673a6.644,6.644,0,0,0-6.643-6.643H547.248a6.644,6.644,0,0,0-6.643,6.643V725a6.642,6.642,0,0,0,6.643,6.641H561.66A6.642,6.642,0,0,0,568.3,725V659.906a32.419,32.419,0,0,1,8.325-22.446c5.395-6.081,12.134-9.038,20.6-9.038,10,0,21.486,3.182,21.486,27.995V725a6.641,6.641,0,0,0,6.641,6.641H640A6.641,6.641,0,0,0,646.644,725V654.789c0-15.924-4.273-28.592-12.705-37.654C625.374,607.941,613.806,603.281,599.553,603.281Z" transform="translate(283.246 16.384)"/>
      <path d="M729.962,606.536H715.55a6.644,6.644,0,0,0-6.643,6.643v.92c-8.592-7.193-19.51-10.818-32.646-10.818a53.906,53.906,0,0,0-41.154,18.071c-10.882,11.833-16.4,27.311-16.4,45.994,0,18.629,5.286,34.1,15.713,45.988,10.648,12.146,24.49,18.3,41.143,18.3,10.661,0,20.207-2.565,28.374-7.621a50.83,50.83,0,0,0,4.968-3.5v6.57c0,10.823-2.63,18.941-7.813,24.13-5.268,5.261-12.705,7.819-22.74,7.819-8.167,0-14.446-2.041-19.2-6.24-4.618-4.08-7.044-9.482-7.412-16.523a6.643,6.643,0,0,0-6.632-6.3H630.7a6.642,6.642,0,0,0-6.64,6.807c.357,14.3,5.672,25.954,15.8,34.633,9.877,8.465,22.906,12.758,38.729,12.758,18.386,0,32.826-5.344,42.92-15.881,10.018-10.456,15.1-25.651,15.1-45.162V613.179A6.644,6.644,0,0,0,729.962,606.536Zm-82.857,60.81c0-12.137,2.949-21.535,9.016-28.734,5.773-6.856,13.045-10.191,22.233-10.191,9.3,0,16.593,3.386,22.292,10.349,6.029,7.368,8.959,16.64,8.959,28.342,0,12.172-2.931,21.667-8.958,29.036-5.7,6.963-13.066,10.349-22.524,10.349a27.006,27.006,0,0,1-21.968-10.378C650.066,688.76,647.105,679.349,647.105,667.346Z" transform="translate(324.168 16.384)"/>
      <path d="M4.358,0h18.98A4.359,4.359,0,0,1,27.7,4.359V120.744a4.358,4.358,0,0,1-4.358,4.358H4.358A4.358,4.358,0,0,1,0,120.744V4.358A4.358,4.358,0,0,1,4.358,0Z" transform="translate(1082.589 622.921)"/>
      <rect width="29.792" height="36.531" rx="4.358" transform="translate(1081.425 578.055)"/>
      <path d="M801.825,603.281a45.951,45.951,0,0,0-26.411,7.9,59.275,59.275,0,0,0-4.839,3.671v-1.673a6.644,6.644,0,0,0-6.643-6.643H749.52a6.644,6.644,0,0,0-6.643,6.643V725a6.642,6.642,0,0,0,6.643,6.641h14.412A6.642,6.642,0,0,0,770.575,725V659.906A32.412,32.412,0,0,1,778.9,637.46c5.395-6.081,12.134-9.038,20.6-9.038,10,0,21.486,3.182,21.486,27.995V725a6.641,6.641,0,0,0,6.641,6.641h14.647A6.641,6.641,0,0,0,848.916,725V654.789c0-15.921-4.273-28.586-12.707-37.654C827.643,607.941,816.075,603.281,801.825,603.281Z" transform="translate(389.225 16.384)"/>
      <path d="M935.933,671.3v-3.022c0-19.5-4.941-35.284-14.683-46.919-10.035-11.993-24.069-18.074-41.709-18.074-16.608,0-30.742,6.33-42.007,18.816-11.087,12.288-16.707,27.9-16.707,46.41,0,20.538,5.439,36.872,16.163,48.544,10.873,11.841,25.582,17.844,43.716,17.844,15.5,0,28.129-4.051,37.542-12.042a48.457,48.457,0,0,0,16.923-31.1,6.643,6.643,0,0,0-6.577-7.569H914.182a6.643,6.643,0,0,0-6.555,5.568c-1.111,6.772-3.842,11.628-8.353,14.848-4.851,3.47-10.85,5.157-18.339,5.157-9.871,0-17.442-3.144-23.144-9.61-4.77-5.4-7.67-12.856-8.658-22.205h80.158A6.642,6.642,0,0,0,935.933,671.3Zm-56.392-42.875c8.734,0,15.259,2.662,19.944,8.136,3.747,4.383,6.169,10.061,7.229,16.94h-56.4a35.287,35.287,0,0,1,8.621-16.372C864.416,631.27,871.157,628.422,879.541,628.422Z" transform="translate(430.067 16.384)"/>
    </g>
  </svg>

)

export { LogoTop, LogoBottom };