import { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Footer from '../components/Footer';
import PageContent from '../components/Page/PageContent';
import IframeOverlay from '../components/Page/IframeOverlay';

const Page = (props) => {

  const { page, windowWidth, windowHeight, isPreview, setCurrentPage } = props;
  const [activeIframeOverlay, setActiveIframeOverlay] = useState({});
  // const [defaultColumnClasses] = useState('col-xxs-12 col-xs-10 offset-xs-1 col-sm-9 col-md-7 offset-md-1 col-lg-6 offset-lg-4 col-xl-5 page--page__inner');
  const [defaultColumnClasses] = useState('col-xxs-12 col-xs-10 col-sm-9 col-md-7 col-lg-6 col-xl-5 page--page__inner');

  useEffect(() => {
    setCurrentPage(page);
  }, [setCurrentPage, page]);

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`page page--page${isPreview === true ? ' page--preview' : ''}`}
    >
      {
        window.location.origin.indexOf('http://localhost:') === 0 &&
        <p>{windowWidth}{' '}({windowWidth < 500 ? 'xxs' : windowWidth < 768 ? 'xs' : windowWidth < 1200 ? 'sm' : windowWidth < 1500 ? 'md' : windowWidth < 1920 ? 'lg' : windowWidth < 2500 ? 'xl' : 'xxl'})</p>
      }
      <div
        className="container-fluid"
        style={{
          minHeight: windowHeight - 96 + 'px',
        }}
      >
        <div className="row page__row">
          <h2 className={`col-xxs-12 page__heading--main ${windowWidth >= 500 ? `border-left border-bottom` : ''} ${defaultColumnClasses}`}>{page.name}</h2>
        </div>
        <PageContent
          {...props}
          defaultColumnClasses={defaultColumnClasses}
          setActiveIframeOverlay={setActiveIframeOverlay}
        />
      </div>
      <AnimatePresence>
        {
          activeIframeOverlay.url &&
          <IframeOverlay {...props} value={activeIframeOverlay} setActiveIframeOverlay={setActiveIframeOverlay} />
        }
      </AnimatePresence>
      <Footer {...props} />
    </motion.main>
  )
}

export default Page;