import { useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { fetchCockpitData } from '../utils/cockpit';

const HomepageLoginForm = (props) => {

  const { windowWidth, windowHeight, setClient, setPages } = props;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordIsShown, setPasswordIsShown] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);

  const submitButton = useRef();

  const handleSubmit = (event) => {
    event && event.preventDefault();

    const checkClientsForMatchingLoginCredentials = (data) => {
      if (data[0]) {
        setClient(data[0]);

        const pageIdsArray = [];

        if (data[0].pages?.length > 0) {

          for (let page of data[0].pages) {
            pageIdsArray.push({
              _id: page._id
            });
          }

          fetchCockpitData(
            'pages',
            setPages,
            true,
            {
              name: true,
              contentBlocks: true,
              thumbnail: true
            },
            {
              published: true,
              $or: [...pageIdsArray]
            }
          );
        }
      } else {
        setLoginFailed(true);
        setPassword('');
      }
    }

    if (password !== '' && username !== '') {
      fetchCockpitData(
        'clients',
        checkClientsForMatchingLoginCredentials,
        true,
        {
          name: true,
          pages: true
        },
        {
          username: username.toLowerCase(),
          password: password
        }
      );
    }
  };

  return (
    <div
      className="homepage__login"
      style={{
        width: windowWidth + 'px',
        height: windowHeight - 96 + 'px'
      }}
    >
    <form
      className="homepage__login__form"
      onSubmit={handleSubmit}
    >
      <label
        htmlFor="username"
      >Username</label>
      <input
        type="text"
          name="username"
          placeholder="Username"
        onChange={(event) => setUsername(event.target.value)}
        value={username}
      />
      <label
        htmlFor="password"
      >Password</label>
      <input
        type={passwordIsShown === false ? 'password' : 'text'}
        name="password"
        placeholder="password"
        onChange={(event) => setPassword(event.target.value)}
        value={password}
        />
        <button
          className="button--show-hide-password"
          onClick={() => setPasswordIsShown(!passwordIsShown)}
        >{passwordIsShown === false ? 'show' : 'hide'} password</button>
        <AnimatePresence>
          {
            username !== '' && password !== '' ?
            <motion.input
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              type="submit"
              value="login"
              ref={submitButton}
              className="button--submit"
            />
              :
              <div className="button--submit-placeholder">
                {loginFailed === true ? 'Login failed, please try again' : ''}
              </div>
          }
        </AnimatePresence>
      </form>
    </div>
  );
};

export default HomepageLoginForm;