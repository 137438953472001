import PageContentRowHeading from './PageContentRowHeading';
import PageContentRowText from './PageContentRowText';
import PageContentRowImage from './PageContentRowImage';
import PageContentRowVideo from './PageContentRowVideo';
import PageContentRowIframe from './PageContentRowIframe';
import PageContentRowLinks from './PageContentRowLinks';

const PageContentRow = (props) => {

  const { row } = props;
  const { field } = row;
  const { name } = field;

  switch (name) {
    case 'heading':
      return <PageContentRowHeading {...props} />
    case 'text':
      return <PageContentRowText {...props} />
    case 'image':
      return <PageContentRowImage {...props} />
    case 'video':
      return <PageContentRowVideo {...props} />
    case 'videoUrl':
      return <PageContentRowVideo {...props} isEmbed={true} />
    case 'iframe':
      return <PageContentRowIframe {...props} />
    case 'links':
      return <PageContentRowLinks {...props} />
    default:
      return null;
  }
}

export default PageContentRow;