import WysiwygContent from '../WysiwygContent';

const PageContentRowText = (props) => {

  const { row, defaultColumnClasses} = props;
  const { value } = row;
  const { text } = value;

  if (text && text !== '') {
    return (
      <div className="row page__row">
        <div className={defaultColumnClasses}>
          <div className="text__wrapper text__wrapper--full-width page__text__wrapper">
            <WysiwygContent content={text} />
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default PageContentRowText;