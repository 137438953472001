import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Header = (props) => {

  const { contact, client, windowWidth, pathname, currentPage } = props;

  return (
    <header className="header container-fluid">
      <div className="row">
        <div className="col-xxs-1 col-xs-1 header__col">
          <AnimatePresence exitBeforeEnter>
            {
              pathname !== '/' && client.name ?
                <motion.div
                  key="back"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="header__back-button__wrapper"
                >
                <Link
                  to="/"
                  title={`Return to ${client.name} homepage`}
                  className={`header__back-button`}
                />
                </motion.div>
                :
                <motion.a
                  key="logo"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  href="https://dxr.zone"
                  rel="noreferrer"
                  target="_blank"
                  className="header__logo__link"
                >
                  <img src="./logo192.png" className="header__logo" alt="logo" />
              </motion.a>
            }
          </AnimatePresence>
        </div>
        {
          windowWidth >= 768 &&
          <div className="col-xxs-5 col-xs-3 col-sm-md-2 border-left header__col">
            {
              contact.email && contact.email !== '' &&
              <a href={`mailto:${contact.email}`} className="header__link" rel="noreferrer" target="_blank">{contact.email}</a>
            }
          </div>
        }
        {
          windowWidth >= 768 &&
          <div className="col-sm-3 col-xl-2 border-left header__col">
            {
              contact.instagram && contact.instagram !== '' &&
              <a href={`https://instagram.com/${contact.instagram}/`} className="header__link" rel="noreferrer" target="_blank">@{contact.instagram}</a>
            }
          </div>
        }
        <div className="col-xxs-6 col-sm-3 header__col no-padding">
          <AnimatePresence>
          {
            currentPage.name &&
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="col-xxs-12 border-left header__col__inner"
              >
                <Link to="/">{currentPage.name}</Link>
              </motion.div>
          }
          </AnimatePresence>
        </div>
        <div className="col-xxs-5 col-xs-4 col-sm-2 header__col no-padding">
          <AnimatePresence>
            {
              client.name && windowWidth > 300 &&
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="col-xxs-12 border-left header__col__inner"
                >
                  <Link to="/">{client.name}</Link>
              </motion.div>
            }
          </AnimatePresence>
        </div>
      </div>
    </header>
  )
}

export default Header;