import { useState } from 'react';

const Footer = (props) => {
  const [year] = useState(new Date().getFullYear());

  return (
    <footer className={`footer container-fluid`}>
      <p>&copy; DXR Zone Ltd {year}</p>
    </footer>
  )
}

export default Footer;