const PageContentRowLink = (props) => {

  const { row, defaultColumnClasses } = props;
  const { value } = row;
  const { links } = value;

  if (links && links.length > 0) {
    return (
      <div className="row page__row">
        <div className={defaultColumnClasses}>
          <div className="page__links">
            {
              links &&
              links.map(
                (link, index) => (
                  link?.value?.url && link?.value?.url !== '' &&
                  <a href={link.value.url} rel="noreferrer" target="_blank" className="page__link" key={index}>
                    {link.value.text ?? link.value.url.replace('http://', '').replace('https://', '')}
                  </a>
                )
              )
            }
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default PageContentRowLink;