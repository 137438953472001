import { Routes, Route } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Header from './Header';
import Preview from '../utils/Preview';
import { LogoTop, LogoBottom } from './Logo';
import Page from '../views/Page';
import HomepageLoginForm from './HomepageLoginForm';
import ClientHub from './ClientHub';

const Content = (props) => {

  const { location, pathname, windowWidth, windowHeight, previewItem, pages, client } = props;

  return (
    <div
      className={`wrapper`}
      style={{
        width: windowWidth + 'px',
        height: windowHeight + 'px'
      }}
    >
      <Header {...props} />
      <div className="logo__wrapper">
        <LogoTop />
        <LogoBottom />
      </div>
      <Preview {...props} />
      {
        !previewItem.collection &&
        <AnimatePresence exitBeforeEnter>
          {
            client._id ?
              <motion.main
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="main"
                style={{
                  height: windowHeight + 'px'
                }}
              >
                {
                  !previewItem.collection &&
                  <AnimatePresence exitBeforeEnter initial={false}>
                    <Routes location={location} key={pathname}>
                      {
                        pages &&
                        pages[0] &&
                        pages.map(
                          (page, index) => (
                            <Route
                              key={`page ${index}`}
                              path={`/page/${page._id}`}
                              element={<Page {...props} page={page} />}
                            />
                          )
                        )
                      }
                      <Route
                        path="*"
                        element={<ClientHub {...props} />}
                      />
                    </Routes>
                  </AnimatePresence>
                }
              </motion.main>
              :
              <HomepageLoginForm {...props} />
          }
        </AnimatePresence>
      }
      {
        previewItem.collection === 'pages' &&
        <Page {...props} page={previewItem} isPreview={true} />
      }
    </div>
  )
}

export default Content;