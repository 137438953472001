import LazyloadImage from '../LazyloadImage';

const PageContentRowIframe = (props) => {

  const { row, setActiveIframeOverlay, defaultColumnClasses } = props;
  const { value } = row;
  const { url, thumbnail, name } = value;

  if (url) {
    return (
      <div className="row page__row">
        <div className={defaultColumnClasses}>
          <button
            className={`page__iframe__wrapper`}
            onClick={() => setActiveIframeOverlay(value)}
            aria-label="view website"
          >
            {
              thumbnail &&
              <LazyloadImage image={thumbnail} isBackgroundImage={true} />
            }
            <p className="page__iframe__view">
              View{name && name !== '' ? `: ${name}` : ' website'}
            </p>
          </button>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default PageContentRowIframe;