import VideoPlayer from '../VideoPlayer';

const PageContentRowVideo = (props) => {

  const { row, isEmbed, defaultColumnClasses } = props;
  const { value } = row;
  const { video } = value;

  if ((video && video !== '' && isEmbed === true) || (isEmbed !== true && video?.path)) {
    return (
      <div className="row page__row">
        <div className={defaultColumnClasses}>
          <VideoPlayer {...props} url={isEmbed === true ? video : video?.path && typeof video.path === 'string' ? '/storage/uploads' + video.path : null} />
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default PageContentRowVideo;